<template>
  <b-overlay :show="loading" class="mt-5">
    <job-display v-if="jobPost" :job-post="jobPost" />
  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { get } from "lodash";
import { BOverlay, BLink, BButton, BModal, BRow, BCol, BCard, BAvatar, BMedia, BBadge } from "bootstrap-vue";

import JobDisplay from "@core/components/shared/jobs/JobDisplay.vue"


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BOverlay,
    JobDisplay
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      jobPost: null
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { job_id } = this.$route.params
        const request = await this.useJwt().sharedService.fetchJob(job_id);

        const { data } = request.data;
        this.jobPost = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

#add-or-update-event-center {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

